import { Fragment, useEffect, useState } from 'react'
import { Transition } from '@headlessui/react'
import { CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/react/outline'
import { XIcon } from '@heroicons/react/solid'

const successIcon = <CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" />
const errorIcon = <ExclamationCircleIcon className="h-6 w-6 text-red-400" aria-hidden="true" />

export default function Notification(props) {
	const {
		icon = null,
		message = 'Successfully saved!',
		description = null,
		timeout = 3000,
		error = false,
		stay = false
	} = props

	const [show, setShow] = useState(false)
	
	useEffect(() => {
		setShow(true)

		if (!stay) {
			setTimeout(() => {
				setShow(false)
			}, timeout)
		}
	}, [])

	return (
		<Transition
			show={show}
			as={Fragment}
			enter="transform ease-out duration-300 transition"
			enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
			enterTo="translate-y-0 opacity-100 sm:translate-x-0"
			leave="transition ease-in duration-100"
			leaveFrom="opacity-100"
			leaveTo="opacity-0"
		>
			<div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
				<div className="p-4">
					<div className="flex items-start">
						<div className="flex-shrink-0">
							{icon ? icon : (error ? errorIcon : successIcon)}
						</div>
						<div className="ml-3 w-0 flex-1 pt-0.5">
							<p className="text-sm font-medium text-gray-900"
								dangerouslySetInnerHTML={{ __html: message.replace(/(?:\r\n|\r|\n)/g, '<br />') }}
							/>
							{description && (
								<p className="mt-1 text-sm text-gray-500">
									{description}
								</p>
							)}
						</div>

						{stay && (
							<div className="ml-4 flex-shrink-0 flex">
								<button
									className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none"
									onClick={() => {
										setShow(false)
									}}
								>
									<span className="sr-only">Close</span>
									<XIcon className="h-5 w-5" aria-hidden="true" />
								</button>
							</div>
						)}
					</div>
				</div>
			</div>
		</Transition>
	)
}
