import React from 'react'
import PropTypes from 'prop-types'

const Button = React.forwardRef(({ primary, outline, textClasses, bgClasses, borderClasses, ...props }, ref) => {
	let xTextClasses = 'text-gray-700 dark:text-white'
	let xBgClasses = 'bg-gray-200 hover:bg-gray-300 dark:bg-gray-700 dark:hover:bg-gray-600'
	let xBorderClasses = 'border border-transparent'

	if (primary) {
		xTextClasses = 'text-white'
		xBgClasses = 'bg-blue-600 hover:bg-blue-700'
		xBorderClasses = 'border border-gray-300 dark:border-gray-500 rounded-md'
	}
	
	if (outline) {
		xBgClasses = 'bg-white dark:bg-gray-700 hover:bg-gray-50'
		xBorderClasses = 'border border-gray-300 dark:border-gray-500 rounded-md'
	}

	if (textClasses) xTextClasses = textClasses
	if (bgClasses) xBgClasses = bgClasses
	if (borderClasses) xBorderClasses = borderClasses

	return (
		<a
			ref={ref}
			{...props}
			className={`
				relative inline-flex items-center justify-center px-4 py-2
				shadow-sm cursor-pointer text-sm font-medium rounded-md focus:outline-none
				${xTextClasses} ${xBgClasses} ${xBorderClasses}
				${props.className}
			`}
		>
			{props.children}
		</a>
	)
})

Button.propTypes = {
	children: PropTypes.node.isRequired,
	primary: PropTypes.bool,
	href: PropTypes.string,
	onClick: PropTypes.func,
	className: PropTypes.string,
}

export default Button