import { Fragment, useEffect, useState } from 'react'
import { Transition, Dialog } from '@headlessui/react'
import { CheckIcon, ExclamationIcon, LogoutIcon } from '@heroicons/react/outline'

export default function Confirmation(props) {
	const {
		icon = null,
		iconComponent = null,
		title = 'Are you sure?',
		description = null,
		fullWidthButtons = false,
		confirmLabel = 'Confirm',
		confirmLabelBg = 'blue',
		cancelLabel = 'Cancel',
		onConfirm = () => {}
	} = props

	const [open, setOpen] = useState(false)

	useEffect(() => {
		setOpen(true)
	}, [])

	const handleConfirm = () => {
		onConfirm()
		setOpen(false)
	}

	return (
		<Transition.Root show={open} as={Fragment}>
			<Dialog as="div" static className="fixed z-40 inset-0 overflow-y-auto" open={open} onClose={setOpen}>
				<div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
					</Transition.Child>

					<span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
						&#8203;
					</span>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						enterTo="opacity-100 translate-y-0 sm:scale-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100 translate-y-0 sm:scale-100"
						leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
					>
						<div
							className={`
								inline-block align-bottom
								bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl
								transform transition-all
								sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6
							`}
						>
							<div>
								{(icon || iconComponent) && (
									<div>
										{iconComponent}
										{icon === 'success' && (
											<div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
												<CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
											</div>
										)}
										{icon === 'warning' && (
											<div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
												<ExclamationIcon className="h-6 w-6 text-red-400" aria-hidden="true" />
											</div>
										)}
										{icon === 'logout' && (
											<div className="mx-auto flex items-center justify-center h-16 w-16 rounded-full bg-gray-100">
												<LogoutIcon className="h-8 w-8 text-gray-400 ml-2" aria-hidden="true" />
											</div>
										)}
									</div>
								)}
								<div className="mt-3 text-center sm:mt-5">
									<Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
										{title}
									</Dialog.Title>
									{description && (
										<div className="mt-2">
											<p className="text-sm text-gray-500">
												{description}
											</p>
										</div>
									)}
								</div>
							</div>
							<div className={`mt-6 sm:mt-8 ${fullWidthButtons ? '' : 'flex flex-row space-x-4'}`}>
								<button
									type="button"
									className={`
										inline-flex justify-center w-full rounded-md
										border border-transparent shadow-sm px-4 py-2
										bg-${confirmLabelBg}-600 text-base sm:text-sm
										font-medium text-white
										hover:bg-${confirmLabelBg}-700
										focus:outline-none
									`}
									onClick={handleConfirm}
								>
									{confirmLabel}
								</button>
								<button
									type="button"
									className={`
										inline-flex justify-center w-full rounded-md
										border border-transparent shadow-sm px-4 py-2
										${fullWidthButtons ? 'mt-3' : ''}
										bg-gray-100 text-base sm:text-sm
										font-medium text-gray-600
										hover:bg-gray-200
										focus:outline-none
									`}
									onClick={() => setOpen(false)}
								>
									{cancelLabel}
								</button>
							</div>
						</div>
					</Transition.Child>
				</div>
			</Dialog>
		</Transition.Root>
	)
}
