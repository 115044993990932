import React, { useCallback, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { SessionProvider } from 'next-auth/react'
import Link from 'next/link'
import Head from 'next/head'
import Script from 'next/script'
import axios from 'axios'
import LogRocket from 'logrocket'
import { IKContext } from 'imagekitio-react'

import Layout from '../components/layout'
import WidgetLayout from '../components/layout-widget'
import Notification from '../components/notification'
import Confirmation from '../components/confirmation'
import getAbsoluteURL from '../utils/getAbsoluteURL'

import 'tailwindcss/tailwind.css'
import '../styles/globals.scss'

LogRocket.init('gumfwe/featuristik')

const MyApp = ({ Component, pageProps }) => {
	const router = useRouter()
	const isWidget = 'widget' in router.query

	const [ready, setReady] = useState(false)
	const [userData, setUserData] = useState(null)
	const [notifications, setNotifications] = useState([])
	const [confirmations, setConfirmations] = useState([])

	useEffect(() => {
		if (!router.isReady) return
		setReady(true)
	}, [router.isReady])

	const displayNotification = (args) => {
		const options = typeof args === 'object' ? args : { message: args }

		if (args.error && !args.timeout) {
			args.timeout = 5000
		}

		const newNotificationId = Math.round(Math.random() * 100000)
		const newNotifiationObject = {
			id: newNotificationId,
			...options,
		}

		setNotifications([...notifications, newNotifiationObject])
	}

	const displayConfirmation = (args) => {
		const options = typeof args === 'object' ? args : { title: args }

		const newConfirmationId = Math.round(Math.random() * 100000)
		const newConfirmationObject = {
			id: newConfirmationId,
			...options,
		}

		setConfirmations([...confirmations, newConfirmationObject])
	}

	if (!ready) return <></>

	const AppLayout = isWidget ? WidgetLayout : Layout

	return (
		<SessionProvider
			options={{
				staleTime: 0,
				refetchInterval: 0,
			}}
			session={pageProps.session}
		>
			<IKContext urlEndpoint={process.env.NEXT_PUBLIC_IK_ENDPOINT}>
				<AppLayout
					displayNotification={displayNotification}
					displayConfirmation={displayConfirmation}
					isWidget={isWidget}
				>
					<Head>
						<title>Featuristik</title>
						<script data-website-id={process.env.UMAMI_WEBSITE_ID} src="https://umami.mfyz.com/umami.js"></script>
						<script src="https://www.googletagmanager.com/gtag/js?id=G-22DHJLM94H"></script>
						<script>
							{`
							window.dataLayer = window.dataLayer || [];
							function gtag(){dataLayer.push(arguments);}
							gtag('js', new Date());
							gtag('config', 'G-22DHJLM94H');
							`}
						</script>
						<script>
							{`
							!function(t,e){var o,n,p,r;e.__SV||(window.posthog=e,e._i=[],e.init=function(i,s,a){function g(t,e){var o=e.split(".");2==o.length&&(t=t[o[0]],e=o[1]),t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}}(p=t.createElement("script")).type="text/javascript",p.async=!0,p.src=s.api_host+"/static/array.js",(r=t.getElementsByTagName("script")[0]).parentNode.insertBefore(p,r);var u=e;for(void 0!==a?u=e[a]=[]:a="posthog",u.people=u.people||[],u.toString=function(t){var e="posthog";return"posthog"!==a&&(e+="."+a),t||(e+=" (stub)"),e},u.people.toString=function(){return u.toString(1)+".people (stub)"},o="capture identify alias people.set people.set_once set_config register register_once unregister opt_out_capturing has_opted_out_capturing opt_in_capturing reset isFeatureEnabled onFeatureFlags".split(" "),n=0;n<o.length;n++)g(u,o[n]);e._i.push([i,s,a])},e.__SV=1)}(document,window.posthog||[]);
							posthog.init('phc_J07MVefcUyYetbaCR6qH7IlVQxGkHX3SrZ6em0Lxwh9',{api_host:'https://app.posthog.com'})
							`}
						</script>
						<meta name="viewport" content="width=device-width, initial-scale=1" />
					</Head>

					<Component
						displayNotification={displayNotification}
						displayConfirmation={displayConfirmation}
						isWidget={isWidget}
						{...pageProps}
					/>

					{/* Notifications */}
					<div
						aria-live='assertive'
						className='notificationsContainer fixed z-50 inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start'
					>
						<div className='w-full flex flex-col items-center space-y-4 sm:items-end'>
							{notifications.map((notification) => (
								<Notification key={notification.id} {...notification} />
							))}
						</div>
					</div>

					{/* Confirmations */}
					{confirmations.map((confirmation) => (
						<Confirmation key={confirmation.id} {...confirmation} />
					))}
				</AppLayout>
			</IKContext>
		</SessionProvider>
	)
}

export default MyApp
