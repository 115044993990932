import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { Disclosure, Menu, Transition, Switch } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import { SunIcon, MoonIcon } from '@heroicons/react/solid'
import { signIn, signOut, useSession } from 'next-auth/react'
import Link from 'next/link'

import Button from './button'

const Layout = ({ children, displayConfirmation, displayNotification }) => {
	const [darkModeEnabled, setDarkModeEnabled] = useState(false)
	const router = useRouter()
	const { data: session } = useSession()

	const projectTabsRoutes = [
		'/projects',
		'/projects/new',
		'/board/[...boardDetailParams]',
		'/feature/[featureId]',
		'/feature/new',
	]
	const navigation = [
		{
			name: 'Dashboard',
			href: '/dashboard',
			current: router.route === '/dashboard',
		},
		{
			name: 'Projects',
			href: '/projects',
			current: projectTabsRoutes.indexOf(router.route) !== -1,
		},
	]

	const userNavigation = [
		{ name: 'My Profile', href: '/my-profile' },
		// {
		// 	name: 'Link other accounts',
		// 	onClick: () => {
		// 		signIn()
		// 	},
		// },
		{
			name: 'Sign out',
			onClick: () => {
				displayConfirmation({
					icon: 'logout',
					title: 'Sign out from Featuristik',
					description: 'Are you sure you would like to sign out of your Featuristik account?',
					confirmLabel: 'Sign out',
					confirmLabelBg: 'gray',
					onConfirm: async () => {
						signOut()
					},
				})
			},
		},
	]

	const toggleDarkMode = () => {
		window.localStorage.setItem('storedDarkModePreference', !darkModeEnabled)
		setDarkModeEnabled(!darkModeEnabled)
	}

	useEffect(() => {
		const storedDarkModePreference = window.localStorage.getItem('storedDarkModePreference')
		if (storedDarkModePreference === true || storedDarkModePreference === 'true') setDarkModeEnabled(true)
	}, [])

	const darkModeSwitch = (
		<Switch
			checked={darkModeEnabled}
			onChange={toggleDarkMode}
			className={`
				${darkModeEnabled ? 'bg-blue-600' : 'bg-gray-200 dark:bg-gray-600'}
				relative inline-flex flex-shrink-0 h-6 w-11 mr-6
				border-2 border-transparent rounded-full cursor-pointer
				transition-colors ease-in-out duration-200 focus:outline-none
			`}
		>
			<span className="sr-only">Dark Mode</span>
			<span
				aria-hidden="true"
				className={`
					${darkModeEnabled ? 'translate-x-5 bg-gray-900' : 'translate-x-0 bg-white'}
					flex items-center justify-center
					pointer-events-none inline-block h-5 w-5 rounded-full shadow
					transform ring-0 transition ease-in-out duration-200
				`}
			>
				{darkModeEnabled ? (
					<MoonIcon className="block h-3 w-3 text-gray-100" aria-hidden="true" />
				) : (
					<SunIcon className="block h-3 w-3 text-yellow-400" aria-hidden="true" />
				)}
			</span>
		</Switch>
	)

	const mobileMenuItemBaseClasses = 'block pl-3 pr-4 py-2 border-l-4 text-base font-medium '
	const mobileMenuActiveItemClasses = mobileMenuItemBaseClasses + ' bg-blue-50 border-blue-500 text-blue-700'
	const mobileMenuItemClasses =
		mobileMenuItemBaseClasses +
		`border-transparent
		text-gray-600 dark:text-gray-300
		hover:bg-gray-50 dark:hover:bg-gray-700 hover:border-gray-300 hover:text-gray-800`
	const mobileMenuProfileItemClasses = `block px-4 py-2
		text-base font-medium text-gray-500 dark:text-gray-300
		hover:text-gray-800 hover:bg-gray-100 dark:hover:text-gray-100 dark:hover:bg-gray-700`

	return (
		<div className={darkModeEnabled ? 'dark' : ''}>
			<div className="min-h-screen bg-gray-100 dark:bg-gray-900 dark:text-white pb-8">
				<Disclosure as="nav" className="bg-white dark:bg-gray-800 shadow-sm z-40">
					{({ open }) => (
						<>
							{/* Desktop Menu */}
							<div className="max-w-screen-2xl mx-auto px-4 sm:px-6 lg:px-8">
								<div className="flex justify-between h-16">
									<div className="flex">
										<Link href={session ? '/dashboard' : '/'}>
											<div className="flex-shrink-0 flex items-center cursor-pointer">
												<div className="mr-2">
													<svg
														fill="#0096FF"
														width="30px"
														height="30px"
														version="1.1"
														viewBox="0 0 100 100"
														xmlns="http://www.w3.org/2000/svg"
													>
														<path
															d="m6.2969 9.9102 12.496 74.996c0.32031 2.0312 2.3867 3.0469 4.25 2.3516l22.109-9.0352 11.824 14.398c1.7852 2.2422 5.5156 0.93359 5.5156-2.0078v-17.031l-42.188-51.707 52.191 45.164 19.285-7.9141c2.4219-0.94531 2.6328-4.2891 0.40234-5.5625l-81.258-46.875c-2.2812-1.3828-5.043 0.61328-4.6289 3.2227z"
															fillRule="evenodd"
														/>
													</svg>
												</div>
												<h4 className="text-2xl font-bold">Featuristik</h4>
											</div>
										</Link>
										<div className="hidden sm:-my-px sm:ml-6 sm:flex sm:space-x-8">
											{session &&
												navigation.map((item) => (
													<Link
														key={item.name}
														href={item.href}
														{...(item.onClick ? { onClick: item.onClick } : {})}
													>
														<a
															className={`
															inline-flex items-center px-1 pt-1 border-b-2
															text-sm font-medium
															${
																item.current
																	? 'border-blue-500 text-gray-900 dark:text-gray-200'
																	: `border-transparent text-gray-500
																	hover:text-gray-700 hover:border-gray-300
																	dark:hover:text-gray-300`
															}
														`}
															aria-current={item.current ? 'page' : undefined}
														>
															{item.name}
														</a>
													</Link>
												))}
											{!session && (
												<a
													onClick={() => {
														signIn()
													}}
													className={`
														inline-flex items-center px-1 pt-1 border-b-2
														text-sm font-medium border-transparent text-gray-500
														hover:text-gray-700 hover:border-gray-300
														dark:hover:text-gray-300
													`}
												>
													Get Started
												</a>
											)}
										</div>
									</div>
									<div className="hidden sm:ml-6 sm:flex sm:items-center">
										{darkModeSwitch}
										{!session && (
											<Button
												className="px-4 py-2"
												primary
												onClick={() => {
													signIn()
												}}
											>
												Sign In
											</Button>
										)}

										{/* Profile + dropdown */}
										{session && (
											<Menu as="div" className="relative z-40">
												{({ open }) => (
													<>
														<div>
															<Menu.Button
																className={`
																	bg-white flex text-sm rounded-full
																	focus:outline-none
																`}
															>
																<span className="sr-only">Open user menu</span>
																{session && session.user && session.user.image ? (
																	<img
																		className="h-8 w-8 rounded-full"
																		src={session.user.image}
																		alt=""
																	/>
																) : (
																	<div
																		className={`h-8 w-8 rounded-full overflow-hidden
																			bg-gray-200 dark:bg-gray-700`}
																	>
																		<svg
																			className={`h-full w-full
																				text-gray-300 dark:text-gray-500`}
																			fill="currentColor"
																			viewBox="0 0 24 24"
																		>
																			<path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
																		</svg>
																	</div>
																)}
															</Menu.Button>
														</div>
														<Transition
															show={open}
															enter="transition ease-out duration-200"
															enterFrom="transform opacity-0 scale-95"
															enterTo="transform opacity-100 scale-100"
															leave="transition ease-in duration-75"
															leaveFrom="transform opacity-100 scale-100"
															leaveTo="transform opacity-0 scale-95"
														>
															<Menu.Items
																static
																className={`
																	origin-top-right absolute right-0 mt-2 w-48
																	rounded-md shadow-lg py-1
																	bg-white ring-1 ring-black ring-opacity-5
																	focus:outline-none
																`}
															>
																{userNavigation.map((item) => (
																	<Menu.Item key={item.name}>
																		{({ active }) => (
																			<a
																				onClick={() => {
																					if (item.href)
																						router.push(item.href)
																					if (item.onClick) item.onClick()
																				}}
																				className={`
																					block px-4 py-2 cursor-pointer
																					text-sm text-gray-700
																					${active ? 'bg-gray-100' : ''}
																				`}
																			>
																				{item.name}
																			</a>
																		)}
																	</Menu.Item>
																))}
															</Menu.Items>
														</Transition>
													</>
												)}
											</Menu>
										)}
									</div>
									<div className="-mr-2 flex items-center sm:hidden">
										{/* Mobile menu button */}
										<Disclosure.Button
											className={`
												inline-flex items-center justify-center p-2 rounded-md
												bg-white dark:bg-gray-700 hover:bg-gray-100
												text-gray-400 hover:text-gray-500
												focus:outline-none
											`}
										>
											<span className="sr-only">Open main menu</span>
											{open ? (
												<XIcon className="block h-6 w-6" aria-hidden="true" />
											) : (
												<MenuIcon className="block h-6 w-6" aria-hidden="true" />
											)}
										</Disclosure.Button>
									</div>
								</div>
							</div>

							{/* Mobile Menu */}
							<Disclosure.Panel className="sm:hidden">
								<div className="pt-2 pb-3 space-y-1">
									{session ? (
										navigation.map((item) => (
											<a
												key={item.name}
												href={item.href}
												className={
													item.current ? mobileMenuActiveItemClasses : mobileMenuItemClasses
												}
												aria-current={item.current ? 'page' : undefined}
											>
												{item.name}
											</a>
										))
									) : (
										<a
											key="get-started"
											href="#"
											className={mobileMenuItemClasses}
											onClick={() => {
												signIn()
											}}
										>
											Get Started
										</a>
									)}
								</div>

								<div className="pb-3 border-t border-gray-200">
									<div className="mt-3 space-y-1">
										<div className={`${mobileMenuProfileItemClasses} flex items-center`}>
											<span className="mr-2">Dark Mode:</span>
											{darkModeSwitch}
										</div>

										{!session && (
											<a
												className={mobileMenuProfileItemClasses}
												href="/signin"
												onClick={() => {
													signIn()
												}}
											>
												Sign In
											</a>
										)}

										{session && (
											<div>
												{userNavigation.map((item) => (
													<Link key={item.name} href={item.href ? item.href : '#'}>
														<a
															{...(item.onClick ? { onClick: item.onClick } : {})}
															className={mobileMenuProfileItemClasses}
														>
															{item.name}
														</a>
													</Link>
												))}
											</div>
										)}
									</div>
								</div>
							</Disclosure.Panel>
						</>
					)}
				</Disclosure>

				<div className="py-4 sm:py-6 lg:py-8">
					<main>
						<div className="max-w-screen-2xl mx-auto px-0 sm:px-6 lg:px-8">{children}</div>
					</main>
				</div>

				<div className="py-4 sm:py-6 lg:py-8">
					<footer
						className={`
							max-w-screen-2xl mx-auto px-0 sm:px-6 lg:px-8
							text-center text-sm text-gray-400 dark:text-gray-600
						`}
					>
						Featuristik &copy; 2021 &nbsp; &middot; &nbsp;
						<Link href="/terms">Terms &amp; Conditions</Link>
						&nbsp; &middot; &nbsp;
						<Link href="/privacy">Privacy Policy</Link>
					</footer>
				</div>
			</div>
		</div>
	)
}

export default Layout
